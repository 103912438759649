import { Box, Container, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material"
import Navbar from "../../components/Navbar"
import { useEffect, useState } from "react"
import DescriptionBox from "../../components/DescriptionBox"
import AssetDetailBox from "../../components/AssetDetailBox"
import AttributeBox from "../../components/AttributeBox"
import { useGetNFT } from "../../common/hooks/useGetNft"
import { useVerification } from "../../common/contexts/verification/provideVerification"
import { GetNFTResponse, Trait } from "opensea-js"
import { DAS } from "helius-sdk"
import AboutBox from "../../components/AboutBox";

import ExternalButton from "../../components/ExternalButton"
import ExperienceBox from "../../components/ExperienceBox"
import PreparationBox from "../../components/PreparationBox"

interface tokenData {
    name: string | undefined,
    imgSource: string | undefined,
}

interface AssetDetailsType {
    currentOwner: string,
    collectionName: string | undefined,
    collectionAddress: string | undefined,
    contractOwner: string,
    tokenEdition: number | undefined,
    tokenSupply: number | undefined
}

interface attributeType {
    attributes: Trait[] | undefined | null | DAS.Attribute[],
    chain: string
}

const Verified = () => {
    const [selected, setSelected] = useState("overview")
    const { contractAddress, tokenId } = useVerification();
    const [tokenData, setTokenData] = useState<tokenData>();
    const [description, setDescription] = useState<string | undefined>()
    const [assetDetails, setAssetDetails] = useState<AssetDetailsType>({
        collectionAddress: "",
        collectionName: "",
        contractOwner: "",
        currentOwner: "",
        tokenEdition: undefined,
        tokenSupply: undefined
    })

    const [properties, setProperties] = useState<attributeType>({
        attributes: undefined,
        chain: ""
    })

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newSelection: string,
    ) => {
        setSelected(newSelection);
    };

    const {data, loading, error, chain, extraData} = useGetNFT({
        nftContract: contractAddress,
        tokenId: tokenId.toString()
    });

    useEffect(() => {
        if (error) {}
        else {
          if (chain === 'eth') {
            let ethData = data as GetNFTResponse;
            setTokenData(
              {
                name: ethData?.nft.name,
                imgSource: ethData?.nft.image_url,
              }
            )
            setDescription(ethData?.nft.description)
            setAssetDetails({
                currentOwner: ethData?.nft.owners[0].address.slice(0, 6) + '...' + ethData?.nft.owners[0].address.slice(-4),
                contractOwner: ethData?.nft.creator.slice(0, 6) + '...' + ethData?.nft.creator.slice(-4),
                collectionAddress: ethData?.nft.collection,
                collectionName: ethData?.nft.collection,
                tokenEdition: undefined,
                tokenSupply: undefined
            })
            setProperties({
                attributes: ethData?.nft.traits,
                chain: "eth"
            })
          } else if (chain === 'sol') {
            let solData = data as DAS.GetAssetResponse
            setTokenData(
              {
                name: solData.content?.metadata.name,
                imgSource: solData.content?.links?.image,
              }
            )
            setDescription(solData.content?.metadata.description)
            setAssetDetails({
                currentOwner: solData.ownership.owner,
                contractOwner: solData.creators ? solData.creators[0].address : "",
                collectionAddress: solData.grouping ? solData.grouping[0].group_value : "",
                collectionName: solData.grouping ? solData.grouping[0].collection_metadata?.name : "",
                tokenEdition: 1,
                tokenSupply: 1
            })
            setProperties({
                attributes: solData.content?.metadata.attributes,
                chain: "sol"
            })
          } else {
            console.log("useEffect, Verified - chain = not found", chain)
          }
        }
    }, [data])

    return !loading ? (
        <Container maxWidth="xl" sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
            marginBottom: "2rem",
            visibility: data ? "visible" : "hidden"
        }}>
            <Navbar/>
            <Container sx={{
                display:"flex",
                flexDirection: "column",
                marginBottom:  "1rem"

            }}>
                <Typography variant="h4">{tokenData?.name}</Typography>
                <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "center",
                    marginTop: "1rem"
                }}>
                    <img src={tokenData?.imgSource} 
                        style={{
                            borderRadius: "0.9375rem",
                            width: '100%',
                            height: '100%',
                            flexShrink: 0,
                            objectFit: 'contain',
                        }}
                        alt="NFT Asset"
                    />
                </Box>
            </Container>
            <Box sx={{marginBottom: "2rem"}}>
                <ToggleButtonGroup
                    value={selected}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="overview" sx={{
                        borderBottom: selected === "overview" ? "#BEA77B" : "",
                        borderStyle: "solid",
                        borderWidth: "thin"
                    }}>
                        <Typography sx={{
                            color: selected === "overview" ? "#BEA77B" : "#FFFFFF"
                        }}>
                            Overview
                        </Typography>
                    </ToggleButton>
                    {/* <ToggleButton value="product" sx={{
                        borderBottom: selected === "product" ? "#BEA77B" : "",
                        borderStyle: "solid",
                        borderWidth: "thin"
                    }}>
                        <Typography
                            sx={{
                                color: selected === "product" ? "#BEA77B" : "#FFFFFF"
                            }}
                        >
                            Product Details
                        </Typography>
                    </ToggleButton> */}
                </ToggleButtonGroup>
            </Box>
            {
                selected === "overview" ?  <>
                    <DescriptionBox description={description}/>
                    <AssetDetailBox 
                        collectionName={assetDetails.collectionName ? assetDetails.collectionName : ""}
                        currentOwner={assetDetails?.currentOwner} 
                        collectionAddress={assetDetails.collectionAddress ? assetDetails.collectionAddress : ""} 
                        contractOwner={assetDetails.contractOwner}
                    />
                    <AttributeBox attributes={properties?.attributes} chain={properties?.chain}/>
                </> : <>
                {/* TODO: figure out a way to customize the details component based on company? */}
                {/* TODO: take in extradata from backend and fit it here */}
                    <AboutBox content={extraData?.About}/>
                    <ExperienceBox 
                        currentOwner={assetDetails?.currentOwner} 
                        collectionAddress={assetDetails.collectionAddress ? assetDetails.collectionAddress : ""} 
                        contractOwner={assetDetails.contractOwner}
                        content={extraData?.Experience}
                    />
                    <PreparationBox content={extraData?.Preparation}
/>
                </>
            }
            <Box sx={{
                margin:"1rem",
                alignSelf: "flex-end"
            }}>
                <ExternalButton nftContract={contractAddress} chain={chain} tokenId={tokenId.toString()}/>
            </Box>
        </Container>
    ) : <Container maxWidth="xl" sx={{
        height: "55.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
        marginBottom: "2rem",
        justifyContent: 'center',
        visibility: data ? "visible" : "hidden"
    }}>
        <Typography>Loading...</Typography>
    </Container>
}

export default Verified