import { AppBar, Button, Container, Toolbar } from "@mui/material"
import logo from '../common/assets/four_circle_logo.png'
import { useNavigate } from "react-router-dom"
import { useVerification } from "../common/contexts/verification/provideVerification"

const Navbar = () =>  {
    // const [show, setShow] = useState(true);
    // const [lastScrollY, setLastScrollY] = useState(0);

    // const controlNavbar = () => {
    //     if (window.scrollY > lastScrollY) { 
    //         // if scroll down hide the navbar
    //         setShow(false);
    //     } else { 
    //         // if scroll up show the navbar
    //         setShow(true);
    //     }
    //     // remember current page location to use in the next move
    //     setLastScrollY(window.scrollY); 
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', controlNavbar);

    //     // cleanup function
    //     return () => {
    //     window.removeEventListener('scroll', controlNavbar);
    //     };
    // }, [lastScrollY]);

    const navigate = useNavigate();
    const { resetVerification, verifyAuth } = useVerification()

    const handleLogoPress = () => {
        navigate("/")
        resetVerification()
    }

    const handleVerifyPress = () => {
        resetVerification();
        verifyAuth();
    }

    return(
        <Container sx={{height: "4rem", marginBottom: "2rem"}}>
            <AppBar sx={{backgroundColor:"#1A3934"}}>
                <Toolbar  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                    <Button onClick={() => handleLogoPress()}>
                        <img src={logo} alt="Logo"/>
                    </Button>
                    <Button onClick={() => handleVerifyPress()}>
                        Verify
                    </Button>
                </Toolbar>
            </AppBar>
        </Container>
    )
}

export default Navbar