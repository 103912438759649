import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import check_circle from '../../common/assets/check_circle.svg'
import logo from '../../common/assets/Logo 1.png'

import * as S from './styles';

import { useVerification } from "../../common/contexts/verification/provideVerification";

const Verification = () => {
    const {
        verifyAuth,
        contractAddress,
        tokenId,
        scanned,
        verified,
        buttonText,
        statusText,
        simulateVerification, //TODO: remove when shipping,
        // simulateNotVerified, //TODO: remove when shipping,
        // simulateScanError //TODO: remove when shipping,
     } = useVerification();

    const matches = useMediaQuery('(max-width:600px)')

    return (
        <S.OuterContainer>
            <S.LogoBox>
                <S.LogoImg src={logo} alt="circles"/>
            </S.LogoBox>
            {
                !matches 
                ? <S.DesktopTypography>
                    Sorry! Desktop is not supported at the moment.
                    <br/>View this page on your phone to verify your products.
                </S.DesktopTypography>
                : <>
                    {
                        !scanned 
                        ? <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                width: '13rem',
                                height: '1.5rem',
                                flexShrink: 0,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                            <Typography sx={{
                                fontSize: '1rem',
                                fontWeight: 400,
                                color: 'red'
                            }}>
                                {statusText}
                            </Typography>
                            <Button 
                                sx={{
                                visibility: scanned ? 'hidden' : 'visible',
                                filter: "drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.50))",
                                width: '12.44rem',
                                height: '2.75rem',
                                flexShrink: 0,
                                borderRadius: '1.5rem',
                                background: '#685631',
                                textTransform: 'none',
                                "&:hover": {
                                    background: '#58492A',
                                },
                                "&:active": {
                                    background: '#58492A',
                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
                                }
                                }}
                                // onClick={() => setScanned(true)}
                                // onClick={() => setVerified(true)}
                                // onClick={() => verifyAuth()}
                                onClick={() => simulateVerification("42uU67W7FfNUEZnP3sYfdmtBvnBKumxi7w8FdNZVhsUD")}
                                // onClick={() => simulateVerification("DQnmD6UAF4ToQFrJ3PPLX3WNSNpXfz3wdbnHMceC4fdV")}
                                // onClick={() => simulateVerification("53qNhPfVSnwyhCRmqcx9zVjDFtQq9coVXEk6ubgEMw55")}
                                // onClick={() => simulateScanError()}
                                // onClick={() => simulateNotVerified()}
                            >
                                <Typography sx={{
                                    fontSize: '1.5rem',
                                    fontWeight: 400
                                }}>
                                {buttonText}
                                </Typography>
                            </Button>
                            </Box>
                        : <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                width: '10.375rem',
                                height: '1.5rem',
                                flexShrink: 0,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            visibility={scanned ? 'visible' : 'hidden'}
                            >
                            <img src={check_circle} alt="checkmark" style={{
                                width: '5rem', height:'5rem', marginTop:'1rem'
                            }}/>
                            </Box>
                    }
                </>
            }
        </S.OuterContainer>
    );
}

export default Verification;