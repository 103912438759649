import httpClient from '../../axios/config'
import { getSolNftDTO, getEthNftDTO } from './dto'
import { GetNFTResponse } from 'opensea-js'
import { IGetSolNFT } from './nft.interface'

export const getSolNft = async (params: getSolNftDTO) => {
    const res = await httpClient.get<IGetSolNFT>(`/sol-sc-verification/${params.nftMint}`)
    if (res.status === 200) {
        return res.data
    } else {
        throw Error("API call error")
    }
}

export const getEthNFt = async (params: getEthNftDTO) => {
    const res = await httpClient.get<GetNFTResponse>(`/sol-sc-verification/${params.contractAddress}/${params.tokenId}`)
    if (res.status === 200) {
        return res.data;
    } else {
        throw Error("API call error") 
    }
}