import { createTheme } from "@mui/material";

// A custom theme for this app
export const defaultTheme = createTheme({
    palette: {
        background: {
            default: "#0A1211",
        },
        primary: {
            main: "#FFFFFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#FFFFFF",
                    fontFamily: "DM Sans",
                    fontStyle: "normal"
                },
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                    color: "#F9F7F3",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    borderRadius: '0.9375rem'
                },
            }
        }
    }
})