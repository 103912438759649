import { Box, Button, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const OuterContainer = styled(Container)({
    display: 'flex', 
    height: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0rem 5.5rem 0rem 5.5625rem',
    justifyContent: 'center',
    gap: '2.35rem',
})

export const LogoBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    width: '13.3rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
})

export const LogoImg = styled('img')({
    width: '100%',
    height: '100%'
})

export const LogoTypography = styled(Typography)({
    fontSize: '3rem',
    fontWeight: 600
})

export const DesktopTypography = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 600,
    textAlign: 'center'
})

// export const VerificationButton = styled(Button)(({scanned}) => ({
//     visibility: scanned ? 'hidden' : 'visible',
//     filter: "drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.50))",
//     width: '12.44rem',
//     height: '2.75rem',
//     flexShrink: 0,
//     borderRadius: '0.6875rem',
//     background: '#F1ECE2',
//     textTransform: 'none',
//     "&:hover": {
//         background: '#E0D5C0',
//     },
//     "&:active": {
//         background: '#E0D5C0',
//         boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
//     }
// }))