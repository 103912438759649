import React, { PropsWithChildren } from 'react'
import { useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { BrowserHistory } from 'history'

interface CustomRouterProps {
  history: BrowserHistory
}

export const CustomRouter: React.FC<PropsWithChildren<CustomRouterProps>> = ({ history, ...props }: CustomRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />
}
