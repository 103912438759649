// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { execHaloCmdWeb } from "@arx-research/libhalo/api/web";

import { useState } from "react"
import { JsonRpcProvider, Contract } from 'ethers';
// import { VERI_ABI, SEPOLIA_VERI_CONTRACT_ADDRESS } from '../../constants/blockchain';
import { verifyMapping as solMapping, chain } from '../../blockchain/sol/veri-sol-sc'
import { recoverAddress } from "ethers";
import { useNavigate } from "react-router-dom";

interface chipResponse {
    "input": { 
       "keyNo": number,
       "digest": string, 
       "message": string 
     }, 
     "signature": {
       "raw": {
           "r": string,
           "s": string,
           "v": number 
         },
         "der": string, 
         "ether": string
     },
     "publicKey": string
 }
 
 interface scResponse {
   contractAddress: string,
   tokenId: number
 }

export interface VerificationContext {
    scanned: boolean,
    verified: boolean,
    buttonText: string,
    statusText: string,
    contractAddress: string,
    tokenId: number,
    verifyAuth: () => void,
    simulateVerification: (contractAddress: string) => void //TODO: remove when shipping,
    simulateScanError: () => void, //TODO: remove when shipping,
    simulateNotVerified: () => void, //TODO: remove when shipping,
    resetVerification: () => void
}

const useProvideVerification = (): VerificationContext => {
    const provider = new JsonRpcProvider(process.env.REACT_APP_SEPOLIA_RPC_URL?.toString() || "");
    const [tokenId, setTokenId] = useState<number>(-1)
    const [contractAddress, setContractAddress] = useState<string>("")
    const [scanned, setScanned] = useState<boolean>(false)
    const [verified, setVerified] = useState<boolean>(false)
    const [buttonText, setButtonText] = useState<string>("Verify")
    const [statusText, setStatusText] = useState<string>("")
    const navigate = useNavigate();

    async function verifyChip() {
        const recentBlock = (await provider.getBlockNumber()).toString();
        const currentTime = Date.now().toString().slice(0, 12);
        const msg = parseInt(currentTime + recentBlock);
        const hexMsg = msg.toString(16).toUpperCase();

        let command = {
          name: "sign",
          keyNo: 1,
          message: hexMsg,
        };
    
        let res: chipResponse;
    
        try {
          // --- request NFC command execution ---
          res = await execHaloCmdWeb(command);
          // the command has succeeded, display the result to the user
          // let response: chipResponse = JSON.stringify(res, null, 4);
          // setStatusText("Chip signed, please wait for verification");
          setScanned(true)
          return res;
        } catch (e) {
          // the command has failed, display error to the user
          // setStatusText('Error: ' + String(e));
          console.log(String(e))
          return null;
        }
    }
    
    // async function verifyMapping(digest: string, r: string, v: number, s: string) {
    //     const veriContract = new Contract(SEPOLIA_VERI_CONTRACT_ADDRESS, VERI_ABI, provider);
    //     const hashedMessage = "0x" + digest;
    //     const prependR = "0x" + r;
    //     const prependS = "0x" + s;

    //     const res = await veriContract.verifyMapping(hashedMessage, v, prependR,prependS);
    //     return {
    //         contractAddress: res[0],
    //         tokenId: Number(res[1])
    //     }
    // } 
    
    async function verifyAuth() {
        setButtonText("Verifying...")
        //1. scan chip
        const chipRes: chipResponse | null = await verifyChip();
        // // //2. submit signature to smart contract -> call verify function on smart contract
        if (chipRes === null) {
            setStatusText("Error: Chip response not found");
            setButtonText("Verify")
        } else {
            const sig = {
              r: "0x" + chipRes.signature.raw.r,
              s: "0x" + chipRes.signature.raw.s,
              v: chipRes.signature.raw.v
            }
            // const scRes: scResponse = await verifyMapping(chipRes.input.digest, chipRes.signature.raw.r, chipRes.signature.raw.v, chipRes.signature.raw.s);
            
            try{
              const chipPk = await recoverAddress("0x" + chipRes.input.digest, sig);
              const scRes: scResponse = await solMapping(chipPk, chain.SOL);
              if (scRes.contractAddress === '0x0000000000000000000000000000000000000000') {
                setVerified(false)
                setScanned(false)
                setButtonText("Verify")
                setStatusText("Error: Product not verified")
              } else {
                setVerified(true)
                setStatusText("")
                setContractAddress(scRes.contractAddress);
                setTokenId(scRes.tokenId);
                navigate("/verified")
              }
            } catch (e) {
              console.log("verifyAuth error:", e)
            }
        }
    }

    // Simulate functions
    async function simulateVerification(contractAddress: string ) { 
      // const scRes: scResponse = await solMapping('0xfe2043100e9119497b609b827b52bdcbd412a3d9', chain.SOL);
      setVerified(true)
      setContractAddress(contractAddress);
      // setContractAddress('0x6535248c4b561dd141c5624030dda53799e73bba');
      // setTokenId(-1);
      setTokenId(5);
      setScanned(true)
      navigate("/verified")
    }

    async function simulateScanError() {
      setStatusText("Error: Chip response not found");
      setButtonText("Verify")
    }

    async function simulateNotVerified() {
      setVerified(false)
      setScanned(false)
      setButtonText("Verify")
      setStatusText("Error: Product not verified")
    }

    async function resetVerification() {
      setVerified(false)
      setScanned(false)
      setButtonText("Verify")
      setStatusText("")
      setContractAddress("")
      setTokenId(-1)
    }

    return {
        scanned,
        verified,
        buttonText,
        statusText,
        contractAddress,
        tokenId,
        verifyAuth,
        simulateVerification,
        simulateScanError,
        simulateNotVerified,
        resetVerification
    }
}

export default useProvideVerification;