import { Box, Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"
import { AlcoholExtraData } from "../common/api/nft/dto";

interface AssetDetailsType {
    currentOwner: string,
    collectionAddress: string,
    contractOwner: string,
    content: AlcoholExtraData['Experience'] | undefined
}


const ExperienceBox = (props: AssetDetailsType) => {
    if (props.content) {
        return (
            <Container sx={{marginBottom:"1rem"}}>
                <Accordion sx={{
                    width: "100%",
                    backgroundColor: "unset",
                    borderStyle: "solid",
                    borderColor: "#1A3934",
                    borderWidth: "thin",
                    borderRadius: "7px !important",
                }}
                defaultExpanded
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                    >
                        <Typography variant="h5">Experience</Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails sx={{flexWrap: "wrap"}}>
                        {
                            Object.entries(props.content).map((key) => {
                                return (
                                    <Box sx={{marginBottom: "1rem"}}>
                                        <Typography variant="h6" color="#BEA77B">
                                            {key[0]}
                                        </Typography>
                                        <Typography variant="h6">
                                        {key[1]}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </AccordionDetails>
                </Accordion>
            </Container>
        )
    } else {
        return (<></>);
    }
}

export default ExperienceBox;