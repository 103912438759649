import React, { createContext, useContext } from 'react'
import useProvideVerification, { VerificationContext } from './provideVerification.state'

interface ProvideVerificationProps {
  children: React.ReactNode
}

const verificationContext = createContext<VerificationContext | null>(null)

export const ProvideVerification: React.FC<ProvideVerificationProps> = ({ children }: ProvideVerificationProps) => {
  const verification = useProvideVerification()

  return <verificationContext.Provider value={verification}>{children}</verificationContext.Provider>
}

export const useVerification = (): VerificationContext => {
  const context = useContext(verificationContext) as VerificationContext
  if (!context) {
    throw new Error('useVerification must be used within a VerificationContext')
  }
  return context
}
