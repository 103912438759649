import { Box, Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"

interface AssetDetailsType {
    currentOwner: string,
    collectionAddress: string,
    contractOwner: string,
    collectionName: string
}


const AssetDetailBox = (props: AssetDetailsType) => {

    return (
        <Container sx={{
            marginBottom:"1rem",
            visibility: props.currentOwner ? "visible" : "hidden"
        }}>
            <Accordion sx={{
                width: "100%",
                backgroundColor: "unset",
                borderStyle: "solid",
                borderColor: "#1A3934",
                borderWidth: "thin",
                borderRadius: "7px !important",
            }}
            defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                >
                    <Typography variant="h5">Details</Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails sx={{flexWrap: "wrap"}}>
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="h6" color="#BEA77B">
                        Collection Name
                    </Typography>
                    <Typography variant="h6">
                        {props.collectionName}
                    </Typography>
                </Box>
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="h6" color="#BEA77B">
                        Edition
                    </Typography>
                    <Typography variant="h6">
                        1/1
                    </Typography>
                </Box>
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="h6" color="#BEA77B">
                        Current Owner
                    </Typography>
                    <Typography variant="h6">
                        {props.currentOwner.includes('.sol') ? props.currentOwner : props.currentOwner.slice(0, 5) + "..." + props.currentOwner.slice(-5)}
                    </Typography>
                </Box>
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="h6" color="#BEA77B">
                        Contract Owner
                    </Typography>
                    <Typography variant="h6">
                        {props.contractOwner.slice(0, 5) + "..." + props.contractOwner.slice(-5)}
                    </Typography>
                </Box>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default AssetDetailBox;