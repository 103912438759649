export type Veri = {
  "version": "0.1.0",
  "name": "veri",
  "instructions": [
    {
      "name": "createLinkEth",
      "accounts": [
        {
          "name": "linkAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "chipPublicKey",
          "type": {
            "array": [
              "u8",
              20
            ]
          }
        },
        {
          "name": "contractAddr",
          "type": "string"
        },
        {
          "name": "tokenId",
          "type": "u32"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createLinkSol",
      "accounts": [
        {
          "name": "linkAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "chipPublicKey",
          "type": {
            "array": [
              "u8",
              20
            ]
          }
        },
        {
          "name": "nftMint",
          "type": "publicKey"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "addAdmin",
      "accounts": [
        {
          "name": "adminAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "adminPublicKey",
          "type": "publicKey"
        },
        {
          "name": "brandName",
          "type": "string"
        },
        {
          "name": "canCreateEthLink",
          "type": "bool"
        },
        {
          "name": "canCreateSolLink",
          "type": "bool"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "editAdmin",
      "accounts": [
        {
          "name": "adminAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "adminPublicKey",
          "type": "publicKey"
        },
        {
          "name": "brandName",
          "type": "string"
        },
        {
          "name": "canCreateEthLink",
          "type": "bool"
        },
        {
          "name": "canCreateSolLink",
          "type": "bool"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "AdminAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "adminPublicKey",
            "type": "publicKey"
          },
          {
            "name": "brandName",
            "type": "string"
          },
          {
            "name": "canCreateEthLink",
            "type": "bool"
          },
          {
            "name": "canCreateSolLink",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "EthLinkAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "chipPublicKey",
            "type": "string"
          },
          {
            "name": "contractAddr",
            "type": "string"
          },
          {
            "name": "tokenId",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "SolLinkAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "chipPublicKey",
            "type": "string"
          },
          {
            "name": "nftMint",
            "type": "publicKey"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "Unauthorized",
      "msg": "The caller is not authorized to perform this action."
    }
  ],
  "metadata": {
    "address": "ExiKPZ8dSomE5TrfVa4fDY8skmw66e7E9yNnT11bWDGJ"
  }
}

export const IDL: Veri = {
  "version": "0.1.0",
  "name": "veri",
  "instructions": [
    {
      "name": "createLinkEth",
      "accounts": [
        {
          "name": "linkAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "chipPublicKey",
          "type": {
            "array": [
              "u8",
              20
            ]
          }
        },
        {
          "name": "contractAddr",
          "type": "string"
        },
        {
          "name": "tokenId",
          "type": "u32"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createLinkSol",
      "accounts": [
        {
          "name": "linkAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "chipPublicKey",
          "type": {
            "array": [
              "u8",
              20
            ]
          }
        },
        {
          "name": "nftMint",
          "type": "publicKey"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "addAdmin",
      "accounts": [
        {
          "name": "adminAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "adminPublicKey",
          "type": "publicKey"
        },
        {
          "name": "brandName",
          "type": "string"
        },
        {
          "name": "canCreateEthLink",
          "type": "bool"
        },
        {
          "name": "canCreateSolLink",
          "type": "bool"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "editAdmin",
      "accounts": [
        {
          "name": "adminAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "adminPublicKey",
          "type": "publicKey"
        },
        {
          "name": "brandName",
          "type": "string"
        },
        {
          "name": "canCreateEthLink",
          "type": "bool"
        },
        {
          "name": "canCreateSolLink",
          "type": "bool"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "AdminAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "adminPublicKey",
            "type": "publicKey"
          },
          {
            "name": "brandName",
            "type": "string"
          },
          {
            "name": "canCreateEthLink",
            "type": "bool"
          },
          {
            "name": "canCreateSolLink",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "EthLinkAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "chipPublicKey",
            "type": "string"
          },
          {
            "name": "contractAddr",
            "type": "string"
          },
          {
            "name": "tokenId",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "SolLinkAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "chipPublicKey",
            "type": "string"
          },
          {
            "name": "nftMint",
            "type": "publicKey"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "Unauthorized",
      "msg": "The caller is not authorized to perform this action."
    }
  ],
  "metadata": {
    "address": "ExiKPZ8dSomE5TrfVa4fDY8skmw66e7E9yNnT11bWDGJ"
  }
}
