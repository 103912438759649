import { Program, Idl, Provider, AnchorProvider } from '@coral-xyz/anchor'
import { PublicKey, Connection, Keypair, ConfirmOptions } from '@solana/web3.js'
import { SOL_PROGRAM_ID } from '../../constants/blockchain'
import { Buffer } from 'buffer'
import { IDL } from '../../constants/veri'
import dotenv from 'dotenv'
dotenv.config();

export enum chain {
    ETH,
    SOL
}

interface solLinkAccount {
    chipPublicKey: string,
    nftMint: PublicKey,
}

interface ethLinkAccount {
    chipPublicKey: string,
    contractAddr: string,
    tokenId: number
}

const veriIdl: Idl = IDL;

export async function verifyMapping(chipPublicKey: string, chainType: chain) {

    const programId: PublicKey = new PublicKey(SOL_PROGRAM_ID)

    const randomKeypair: Keypair = Keypair.generate();
    const confirmation: ConfirmOptions = {
        commitment: 'confirmed'
    }

    const seedBuffer = convertAddrToParams(chipPublicKey)

    const dummyWallet = {
        publicKey: randomKeypair.publicKey,
        signTransaction: async (tx: any) => tx,
        signAllTransactions: async (txs: any) => txs
    };

    const connection: Connection = new Connection(process.env.REACT_APP_SOL_DEVNET_API ||  "")
    const provider: Provider = new AnchorProvider(connection, dummyWallet, confirmation)
    const program: Program = new Program(veriIdl, SOL_PROGRAM_ID, provider)

    try {
        if (chainType === chain.ETH) {
            const [mappingAccount, bump] = await PublicKey.findProgramAddressSync(
                [seedBuffer],
                programId
            );
            const account = await getEthPDA(mappingAccount, program)

            return {
                contractAddress: account.contractAddr,
                tokenId: account.tokenId
            }
        } else {
            const [mappingAccount, bump] = await PublicKey.findProgramAddressSync(
                [seedBuffer],
                programId
                );
                console.log("mapping acc:", mappingAccount.toBase58())

            const account = await getSolPDA(new PublicKey('7Bzn6iDNb9VVMQbVttmChKHa745QYYXr3XZ4CkvLgz7g'), program)
            // const account = await getSolPDA(mappingAccount, program)
            console.log(account)
            return {
                contractAddress: account.nftMint.toBase58(),
                tokenId: -1
            }
        }
    } catch (e) {
        console.log(e)
        return {
            contractAddress: "0x0000000000000000000000000000000000000000",
            tokenId: -1
        }
    }
}

async function getSolPDA(pda_address: PublicKey, program: Program): Promise<solLinkAccount> {
    const account = await program.account.solLinkAccount.fetch(pda_address) as unknown
    console.log("sol pda:", account)
    return account as solLinkAccount;
}
async function getEthPDA(pda_address: PublicKey, program: Program): Promise<ethLinkAccount> {
    const account = await program.account.ethLinkAccount.fetch(pda_address) as unknown
    return account as ethLinkAccount;
}

function convertAddrToParams(chipPk: string) {
    const seedBuffer = Buffer.from(chipPk.slice(2), 'hex')
    return seedBuffer
}