import { Box, Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"

const DescriptionBox = (props: {description: string | undefined}) => {
    return (
        <Container sx={{marginBottom:"1rem"}}>
            <Accordion sx={{
                    width: "100%",
                    backgroundColor: "unset",
                    borderStyle: "solid",
                    borderColor: "#1A3934",
                    borderWidth: "thin",
                    borderRadius: "7px !important",
                    visibility:  props.description ? "visible" : "hidden"
                }}
                defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                >
                    <Typography variant="h5">Description</Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                    <Typography variant="h6">
                        {props.description}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default DescriptionBox;