import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { defaultTheme } from '../theme'
import { history } from './history'
import { CustomRouter } from './CustomRouter'
import { ProvideVerification } from '../common/contexts/verification/provideVerification'
import Verification from '../pages/Verification/Verification'
// import Transfer from '../pages/Transfer/Transfer'
import Verified from '../pages/Verified/Verified'

const AppRoutes = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <CustomRouter history={history}>
                <ProvideVerification>
                    <Routes>
                    <Route
                        path="/"
                        element={
                        <Verification/>
                        }
                    />
                    <Route
                        path="/Verified"
                        element={
                            <Verified/>
                        }
                    />
                    </Routes>
                </ProvideVerification>
            </CustomRouter>
        </ThemeProvider>
    )
}

export default AppRoutes;