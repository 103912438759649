import { Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"
import { AlcoholExtraData } from "../common/api/nft/dto";

interface Props {
    content: AlcoholExtraData["About"] | undefined
}

const AboutBox = (props: Props) => {
    return (
        <Container sx={{marginBottom:"1rem"}}>
            <Accordion sx={{
                    width: "100%",
                    backgroundColor: "unset",
                    borderStyle: "solid",
                    borderColor: "#1A3934",
                    borderWidth: "thin",
                    borderRadius: "7px !important",
                    visibility:  props.content ? "visible" : "hidden"
                }}
                defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                >
                    <Typography variant="h5">About</Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                    <iframe title='bts' src="https://shdw-drive.genesysgo.net/5MGQ8q3BPBiL2QVwiMNpqyDddDtEZnjCMKxU5TXSH9Rg/bts-teochew.mp4?enablejsapi=1" height="500" width='100%'></iframe>
                    {/* <Typography variant="h6">
                    {props.content?.History}
                    </Typography> */}
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default AboutBox;