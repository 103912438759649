import { Box, Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"
import { AlcoholExtraData } from "../common/api/nft/dto";

interface Props {
    content: AlcoholExtraData["Preparation"] | undefined
}

const PreparationBox = (props: Props) => {
    if (props.content) {
        return (
            <Container sx={{marginBottom:"1rem"}}>
                <Accordion sx={{
                    width: "100%",
                    backgroundColor: "unset",
                    borderStyle: "solid",
                    borderColor: "#1A3934",
                    borderWidth: "thin",
                    borderRadius: "7px !important",
                }}
                defaultExpanded
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                    >
                        <Typography variant="h5">Preparation</Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails sx={{flexWrap: "wrap"}}>
                    {
                        Object.entries(props.content).map((key) => {
                            return (
                                <Box sx={{marginBottom: "1rem"}}>
                                    <Typography variant="h6" color="#BEA77B">
                                        {key[0]}
                                    </Typography>
                                    <Typography variant="h6">
                                    {key[1]}
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                    {/* <Box sx={{marginBottom: "1rem"}}>
                        <Typography variant="h6" color="#BEA77B">
                            On the Rocks
                        </Typography>
                        <Typography variant="h6">
                        By chilling the whiskey, the drink will be diluted. This means that the alcohol percentage in the whisky is being lowered, which can moderate the stronger flavours and particular characteristics of the spirit.
                        </Typography>
                    </Box>
                    <Box sx={{marginBottom: "1rem"}}>
                        <Typography variant="h6" sx={{color: "#BEA77B"}}>
                            Neat
                        </Typography>
    
                        <Typography variant="h6">
                        Enjoy the original flavours of the drink, experience the flavours as intended
                        </Typography>
                    </Box> */}
                    </AccordionDetails>
                </Accordion>
            </Container>
        )
    } else {
        return <></>
    }
}

export default PreparationBox;