import { Box, Container, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material"
import { DAS } from "helius-sdk";
import { Trait } from "opensea-js";


interface attributeType {
    attributes: Trait[] | undefined | null | DAS.Attribute[],
    chain: string
}

const AttributeBox = (props: attributeType) => {

    let data;

    if (props.chain === "sol") {
        data = props.attributes as DAS.Attribute[]
    } else if(props.chain === "eth") {
        data = props.attributes as Trait[]
    }

    return (
        <Container sx={{
            visibility: props.attributes ? "visible" : "hidden"
        }}>
            <Accordion sx={{
                width: "100%",
                backgroundColor: "unset",
                borderStyle: "solid",
                borderColor: "#1A3934",
                borderWidth: "thin",
                borderRadius: "7px !important",
            }}
            defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDown sx={{color: "white"}}/>}
                >
                    <Typography variant="h5">Attributes</Typography>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent:"space-evenly"
                    }}
                >
                    {
                        data ? data.map((detail) => {
                            return (
                                <Box sx={{
                                    marginX: "0.2rem",
                                    marginY: "0.5rem",
                                    borderStyle: "solid",
                                    borderWidth: "thin",
                                    borderColor: "#1A3934",
                                    borderRadius: "7px",
                                    padding: "0.5rem",
                                    minWidth: "47.5%"

                                }}>
                                    <Typography variant="h6">{detail.trait_type}</Typography>
                                    <Typography variant="h6" sx={{color: "#BEA77B"}}>{detail.value.toString()}</Typography>
                                </Box>
                            )
                        }) : null
                    }
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default AttributeBox;