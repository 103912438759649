import { useState, useEffect } from 'react';
import { GetNFTResponse } from 'opensea-js';
import { getSolNft, getEthNFt } from '../api/nft/nft';
import { DAS } from 'helius-sdk';

import dotenv from 'dotenv';
import { AlcoholExtraData } from '../api/nft/dto';
dotenv.config()

interface params {
    nftContract: string,
    tokenId: string
}

export const useGetNFT = (props: params) => {
    const [data, setData] = useState<GetNFTResponse | null | void | DAS.GetAssetResponse>(null)
    const [extraData, setExtraData] = useState<AlcoholExtraData>();
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>('')
    const [chain, setChain] = useState<string>('')

    useEffect(() => {
        if (!props.nftContract)return
        const getNft = async () => {
            console.log("nftcontract:", props.nftContract)
            try {
                if (props.nftContract.startsWith("0x")) {
                    const nftResult = await getEthNFt({
                        contractAddress: props.nftContract,
                        tokenId: props.tokenId
                    })
                    setChain('eth')
                    setData(nftResult)    
                    setLoading(false)
                } else {
                    const result = await getSolNft({
                        nftMint: props.nftContract
                    })
                    console.log("result:", result)
                    let data = result.nftResult
                    if (result.sns !== '') {
                        data.ownership.owner = result.sns + '.sol'
                    }
                    if (result.extraData) {
                        console.log(result.extraData);
                        setExtraData(result.extraData);
                    }
                    setData(data)
                    setChain('sol')
                    setLoading(false)
                }
            }
            catch (e) {
                console.log("getNFT error:", e)
                setError("error encountered")
                setLoading(true)
            }
        } 
        getNft();
    }, [props.nftContract])

    return {
        data,
        loading,
        error,
        chain,
        extraData
    }
}
