import { Box } from '@mui/material';
import opensea from '../common/assets/opensea.svg'
import solanafm from '../common/assets/solanafm.png'
import insta from '../common/assets/Instagram_Glyph_Gradient.png'

interface properties {
    nftContract: string,
    tokenId: string,
    chain: string
}

export default function ExternalButton(props: properties) {
    let url = ""
    let image;
    if (props.chain === "eth") {
        url = "https://opensea.io/assets/ethereum/" + props.nftContract + "/" + props.tokenId
        image = opensea;
    } else {
        url = "https://solana.fm/address/" + props.nftContract;
        image = solanafm;
    }
    return (
        <Box>
            <a 
                href='https://instagram.com/craftycrafty.sg' style={{alignSelf:'flex-end', margin:'0.5rem'}}
                target="_blank"
                rel="noreferrer"
            >
                <img src={insta} alt='opensea' style={{width:'1.375rem', height:'1.375rem'}}/>
            </a>
            <a 
                href={url} style={{alignSelf:'flex-end'}}
                target="_blank"
                rel="noreferrer"
            >
                <img src={image} alt='opensea' style={{width:'1.375rem', height:'1.375rem'}}/>
            </a>
        </Box>
    )
}